import PublicIcon from "@mui/icons-material/Public";
import { makeStyles } from "@mui/styles";
import logo from "assets/logo.png";
import moment from 'moment';
import Form1RightSection from './Form1RightSection';
import SenderRecieverForm2 from './SenderRecieverForm2';
import SenderReciver from './SenderReciver';
import { currencyTypes } from "../../Parcel";
import clsx from "clsx";

const useStyeles = makeStyles((theme) => ({
    wrapper: {
        width: "100%",
        display: "flex",
        justifyContent: 'center',
        flexDirection: "column",
    },
    wrapper2: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    form1: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    form1Header: {
        display: "flex",
        justifyContent: "space-between",
        height: 40,
        width: "100%",
        borderBottom: "2px solid #444",
        marginBottom: 13,
    },
    form2Header: {
        display: "flex",
        justifyContent: "space-between",
        height: 70,
        width: "100%",
    },
    form1LeftSection: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minHeight: "100%",
    },
    packageText: {
        fontSize: 12,
        fontWeight: 400,
        textAlign: "left",
    },
    headBarCode: {
        height: 60,
        textAlign: "left",
        boxSizing: "border-box",
    },
    officeText: { textAlign: "left", fontSize: 12, fontWeight: 400 },
    form1RightSection: {
        height: "100%",
        justifyContent: "space-between",
        alignItems: "self-end",
        alignContent: "flex-end",
        display: "flex",
        textAlign: "right",
        flexDirection: "column",
    },
    imgLogo: {
        width: 90,
    },
    imgLogo2: {
        width: 110,
    },
    form2: { color: "#000", borderTop: '2px solid #888', display: 'flex', flexDirection: 'column', height: "500px", maxHeight: '500px' },
    section3: { display: "flex", height: "max-content" },
    vat: {
        width: "90%",
        border: "1px solid black",
        display: 'flex',
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'center',
        "& div": {
          height: "16px",
        }
      },
      row: {
        display: 'flex',
        borderTop: '1px solid black',
        "&:first-child": {
            border: 'none'
        }
      },
      col76:{
        width: "76%",
        textAlign: 'end',
        paddingRight: '2px',
        borderRight: '1px solid black',
      },
      col42:{
        width: "42%",
        textAlign: 'start',
        borderRight: '1px solid black',
        paddingLeft: '3px'
      },
      col25:{
        width: "25%",
        borderRight: '1px solid black',
        "&:last-child": {
            border: 'none'
        }
      },
      col9:{
        width: "9%",
        borderRight: '1px solid black'
      },
      header: {
        textAlign: 'center',
        fontWeight: 'bold'
      }
}));

export const setVAT = (data, classes) => {
    const { price } = data;
    const netto = +((+price * 0.1) / 1.23).toFixed(2);
    const brutto = +((netto * 0.23) + netto).toFixed(2);
    if(data.currency_type_id !== '2') return <></>
    return (<div className={classes.vat}>
        <div className={classes.row}>
            <div className={clsx(classes.col42, classes.header)}>Kierunek</div>
            <div className={clsx(classes.col25, classes.header)}>Netto</div>
            <div className={clsx(classes.col9, classes.header)}>VAT</div>
            <div className={clsx(classes.col25, classes.header)}>Brutto</div>
        </div>
        <div className={classes.row}>
            <div className={classes.col42}>{"WAR->PL-BOARDER"}</div>
            <div className={classes.col25}>PLN {netto}</div>
            <div className={classes.col9}>23%</div>
            <div className={classes.col25}>PLN {brutto}</div>
        </div>
        <div className={classes.row}>
            <div className={classes.col42}>{'PL-BOARDER->ARM'}</div>
            <div className={classes.col25}>PLN {(+price * 0.9).toFixed(2)}</div>
            <div className={classes.col9}>0%</div>
            <div className={classes.col25}>PLN {(+price * 0.9).toFixed(2)}</div>
        </div>
        <div className={classes.row}>
            <div className={classes.col76}>Całkowita opłata za transport</div>
            <div className={classes.col25}>PLN {price}</div>
        </div>
        

    </div>)
}

const PrintType_B = ({ data, tracking_number }) => {
    const classes = useStyeles();

    const { local_delivery_states } = data;

    return (
        <div style={{display: 'flex', flexDirection: 'column', minHeight: 1030, height: 1030, maxHeight: 1035}}>
            <div className={classes.wrapper} >
                <div className={classes.form1}>
                    <div className={classes.form1Header}>
                        <div className={classes.form1RightSection} >
                            <div style={{ display: "flex", alignItems: "center", fontSize: 17 }}>
                                <PublicIcon style={{ opacity: '0.5', marginRight: "5px", width: 20 }} />
                                www.barekam.am
                            </div>
                        </div>
                        <div className={classes.form1RightSection}>
                            <img src={logo} alt="" className={classes.imgLogo} />
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ width: "36%" }}>
                            <SenderReciver data={data} />
                        </div>
                        <div style={{ width: "63%" }}>
                            <Form1RightSection
                                data={data}
                                tracking_number={tracking_number}
                            />
                        </div>
                    </div>
                    <div className={classes.section3}>
                        <div style={{ fontSize: 15, fontWeight: 600 }}>
                            Date: {moment(data?.date_added).format("YYYY - MM - DD")}
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.wrapper2}>
                <div style={{ fontSize: local_delivery_states ? 18 : 16, fontWeight: 600, margin: '2px 0px', textAlign: "center", fontFamily: 'sans-serif' }}>{local_delivery_states ? local_delivery_states : "REGION"}</div>
                <div className={classes.form2}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
                        <div style={{ fontSize: 15, fontWeight: 600 }}>Date: {moment(data?.date_added).format("YYYY - MM - DD")}</div>
                        <div style={{ fontSize: 14, fontWeight: 900,  }}>Confirmation of sending a copy</div>
                        <img src={logo} alt="" className={classes.imgLogo2} />
                    </div>
                    <SenderRecieverForm2 data={data} />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "end",
                            fontSize: 13,
                            marginTop: 2,
                            borderBottom: "1.5px solid #888",
                            paddingBottom: 2,
                            textAlign: "start",
                            width: "100%",
                            flex: 1,
                            alignItems: "end",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                marginTop: 3,
                                alignItems: "end",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    width: "55%",
                                    marginRight: 5,
                                    height: 'max-content'
                                }}
                            >
                                {data?.payment_bank_transfer ?
                                    data?.payment_bank_transfer > 0 && (
                                        <>
                                            <div style={{ fontWeight: 600 }}>
                                                <span style={{ fontWeight: 600 }}>Company: </span>{" "}
                                                BAREKAM POLAND Sp. z o.o.
                                            </div>
                                            <div sty le={{ fontSize: 13, whiteSpace: 'nowrap' }}>
                                                <span style={{ fontWeight: 600 }}>
                                                    Bank account number:{" "}
                                                
                                                {data.sender.country_id === "179" ||
                                                    data.receiver.country_id === "179"
                                                    ? "Acc. Zloty: PL66 1090 0088 0000 0001 5287 8299"
                                                    : "Acc. EURO: PL31 1090 0088 0000 0001 5287 8347"}
                                                    </span>
                                            </div>
                                            <div style={{ fontWeight: 600 }}>
                                                <span style={{ fontWeight: 600 }}>
                                                    NIP:{" "}
                                                </span>
                                                1133076356
                                            </div>
                                            <div style={{ fontWeight: 600 }}>
                                                <span style={{ fontWeight: 600 }}>Number BIC: </span>{" "}
                                                WBKPPLPP
                                            </div>
                                            <div style={{ fontWeight: 600 }}>
                                                <span style={{ fontWeight: 600 }}>Invoice Number: </span>{" "}
                                                {tracking_number}
                                            </div>
                                            <div style={{ fontWeight: 600 }}>
                                                <span style={{ fontWeight: 600 }}>
                                                    Transfer amount:{" "}
                                                </span>
                                                {data?.payment_bank_transfer} {currencyTypes[data.currency_type_id + "symbol"]}
                                            </div>
                                            <div style={{ fontWeight: 600 }}>
                                                <span style={{ fontWeight: 600 }}>Title: </span> Package
                                                -{tracking_number}
                                            </div>
                                            {setVAT(data, classes)}
                                        </>
                                    ) : (<>
                                        <div style={{ fontSize: 13, whiteSpace: 'nowrap', color: 'transparent'}}>
                                                <span>
                                                    Bank account number:{" "}
                                                
                                                {data.sender.country_id === "179" ||
                                                    data.receiver.country_id === "179"
                                                    ? "Acc. Zloty: PL66 1090 0088 0000 0001 5287 8299"
                                                    : "Acc. EURO: PL31 1090 0088 0000 0001 5287 8347"}
                                                    </span>
                                        </div>
                                        <div style={{ fontWeight: 600 }}>
                                            <span style={{ fontWeight: 600 }}>Company: </span>{" "}
                                            BAREKAM POLAND Sp. z o.o.
                                        </div>
                                        
                                        <div style={{ fontWeight: 600 }}>
                                            <span style={{ fontWeight: 600 }}>
                                                NIP:{" "}
                                            </span>
                                            1133076356
                                        </div>
                                        <div style={{ fontWeight: 600 }}>
                                            <span style={{ fontWeight: 600 }}>Invoice Number: </span>{" "}
                                            {tracking_number}
                                        </div>
                                        {(data?.payment_cash || data?.price) &&
                                            <div style={{ fontWeight: 600 }}>
                                                <span style={{ fontWeight: 600 }}>Cash paid: </span>{" "}
                                                {data?.payment_cash || data?.price}
                                            </div>}
                                        {setVAT(data, classes)}
                                    </>)}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right', fontSize: 12, fontWeight: 600 }}>
                                <div style={{whiteSpace: 'pre'}}>Tracking No / Բեռնային համար {tracking_number}</div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'right' }}>
                                    <PublicIcon style={{ marginRight: "5px", width: 20 }} />
                                    www.barekam.am
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrintType_B