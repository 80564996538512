import PhoneInput from "react-phone-number-input";
import { useStyles } from "./styles";
import { useEffect, useState } from "react";
import SERVICES from "services";

const PhoneField = ({
  telephoneErr,
  setInputErrors,
  disabled,
  value,
  setForm,
  name = 'telephone',
  isRequired = true,
  hideLabel,
}) => {
  const classes = useStyles();
  const [phoneCountries, setPhoneCountries] = useState([]);

  useEffect(() => {
    SERVICES.FILTERS.getCountries().then((res) => {
      setPhoneCountries(res.data.map((el) => el.code));
    });
  }, []);

  return (
    !!phoneCountries.length && (
      <>
        {!hideLabel && (
          <div className={classes.label}>
            Phone {name === 'sec_telephone' ? '2' : ''} {isRequired && <span className={classes.asterisk}>*</span>}
          </div>
        )}
        <div className={[telephoneErr ? classes.errorField : ""]}>
          <PhoneInput
            className={classes.phoneInput}
            international
            placeholder="Phone"
            countries={phoneCountries}
            value={value}
            onChange={(val) => {
              if (val && telephoneErr) {
                setInputErrors &&
                  setInputErrors((prev) => {
                    const { telephone, ...rest } = prev;
                    return rest;
                  });
              }
              setForm((prevState) => ({
                ...prevState,
                [name]: val || "",
              }));
            }}
            disabled={disabled}
          />
          <p style={{ display: "none" }}>{telephoneErr ? telephoneErr : 'Required field.'}</p>
        </div>
      </>
    )
  );
};

export default PhoneField;
